import React from "react"
import { Link } from "gatsby"

export default function() {
  return (
    <div style={{ padding: 100 }}>
      <div>
        <h1>Security</h1>
        <p>
          Prowess takes security very seriously, and only uses best-in-class
          vendors for storing data and providing service.
        </p>
        <h2>Data Security</h2>
        <ul>
          <li>
            <p>
              Prowess hosts all its software in Amazon Web Services (AWS)
              facilities in the USA or on the Google Cloud Platform (GCP).
              Amazon and Google both provide an extensive list of compliance and
              regulatory assurances, including SOC 13, and ISO 27001. See their
              compliance and security documents for more detailed information.
            </p>
          </li>
          <li>
            <p>
              We use industry-standard PostgreSQL, Elastic Search and Mongo DB
              data storage systems hosted at AWS and/or by the respective
              vendors.
            </p>
          </li>
          <li>
            <p>
              All customer data is encrypted at rest and in transit with
              AES-256.
            </p>
          </li>
        </ul>
        <h2>Policies</h2>
        <ul>
          <li>
            <p>
              Prowess's privacy & security team includes a Data Protection
              Officer (DPO), Chief Information Security Officer (CISO), and a
              Compliance Officer, who continuously ensure that Prowess's
              practices and products comply with official regulations. Our{" "}
              <Link to="/terms">Terms and Conditions</Link>,{" "}
              <Link to="/privacy">Privacy Policy</Link>, and{" "}
              <a href="https://docs.google.com/document/d/1EWoP5lTZQEIeemB4UQ2rYiJql9xQXpjLww0vOGbQPNQ">
                Data Processing Agreement (DPA)
              </a>{" "}
              are up-to-date.
            </p>
          </li>
          <li>
            <p>
              As per our DPA, we list our third-party sub-processors in this{" "}
              <a href="https://docs.google.com/document/d/1xes0zZI85LsUJewYmRoznjt7I5-lOB7QIHrsRF06Q7k/">
                living document.
              </a>
            </p>
          </li>
        </ul>
        <p>
          If you have any questions, please email{" "}
          <a href="mailto:dpo@prowess.app">dpo@prowess.app</a>
        </p>
      </div>
    </div>
  )
}
